
const MainMenu = () => {
    return (
        <div className={'mainmenu'}>
            <ul>
                <li><a href='/'>Home</a></li>
                <li><a href='/match3'>Candy Crush</a></li>
                <li><a href='/wheel'>Wheel of Fortune</a></li>
                <li><a href='/lottery'>Lottery</a></li>
            </ul>
        </div>
    )
}

export default MainMenu;