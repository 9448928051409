/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

const gameSize = 8;
const tileSize = 50;
const candyColors = [
    'blue',
    'green',
    'orange',
    'purple',
    'red',
    'yellow',
    'cyan'
];
const blank = 'blank';

const tilesBoardSize = {
    width: (gameSize * tileSize) + 'px',
    height: (gameSize * tileSize) + 'px',
};

const scoreBoardSize = {
    height: (gameSize * tileSize) + 'px',
};

const swapClassNames = [
    'swap-left-first', 'swap-left-second', 
    'swap-right-first', 'swap-right-second', 
    'swap-top-first', 'swap-top-second', 
    'swap-bottom-first', 'swap-bottom-second', 
];

const MatchThreeApp = () => {

    const [ currentTilesArrangement, setCurrentTilesArrangement ] = useState([]);
    const [ tileBeingDragged, setTileBeingDragged ] = useState(null); 
    const [ tileBeingReplaced, setTileBeingReplaced ] = useState(null);
    const [ swappingInProgress, setSwappingInProgress] = useState(false);
    const [ scoreDisplay, setScoreDisplay ] = useState(0);

    const CreateBoard = () => {
        const randomTilesArrangement = [];

        for ( let i = 0; i < gameSize * gameSize; i++ ) {
            const randomColor = candyColors[Math.floor(Math.random() * candyColors.length)];
            randomTilesArrangement.push(randomColor);
        }

        setCurrentTilesArrangement(randomTilesArrangement);
    }

    const getNotValidTiles = (rowSize) => {
        const indices = [];
        for ( let i = 0; i < gameSize ; i++ ) {
            for ( let j = 0; j < gameSize ; j++ ) {
                if (j > gameSize - rowSize) {
                    indices.push(gameSize * i + j);
                }
            }
        }
        return indices;
    }

    const checkForColumnOfThree = () => {
        const tilesToCheck = gameSize * (gameSize - 2);
        for (let i = 0; i < tilesToCheck; i++) {
            const columnOfThree = [i, i + gameSize, i + gameSize * 2];
            const decidedColor = currentTilesArrangement[i];
            const isBlank = decidedColor === 'blank';

            if ( columnOfThree.every(tile => currentTilesArrangement[tile] === decidedColor && !isBlank)) {
                setScoreDisplay((score) => score + 3);
                columnOfThree.forEach(tile => currentTilesArrangement[tile] = blank);
                return true;
            }
        }
    }

    const checkForRowOfThree = () => {
        const tilesToCheck = gameSize * gameSize;
        for (let i = 0; i < tilesToCheck; i++) {
            const rowOfThree = [i, i + 1, i + 2];
            const decidedColor = currentTilesArrangement[i];
            const notValid = getNotValidTiles(3);
            const isBlank = decidedColor === 'blank';

            if ( notValid.includes(i) ) continue;

            if ( rowOfThree.every(tile => currentTilesArrangement[tile] === decidedColor && !isBlank)) {
                setScoreDisplay((score) => score + 3);
                rowOfThree.forEach(tile => currentTilesArrangement[tile] = blank);
                return true;
            }
        }
    }

    const checkForRowOfFour = () => {
        const tilesToCheck = gameSize * gameSize;
        for (let i = 0; i < tilesToCheck; i++) {
            const rowOfFour = [i, i + 1, i + 2, i + 3];
            const decidedColor = currentTilesArrangement[i];
            const notValid = getNotValidTiles(4);
            const isBlank = decidedColor === 'blank';

            if ( notValid.includes(i) ) continue;

            if ( rowOfFour.every(tile => currentTilesArrangement[tile] === decidedColor && !isBlank)) {
                setScoreDisplay((score) => score + 4);
                rowOfFour.forEach(tile => currentTilesArrangement[tile] = blank);
                return true;
            }
        }
    }

    const checkForColumnOfFour = () => {
        const tilesToCheck = gameSize * (gameSize - 3);
        for (let i = 0; i < tilesToCheck; i++) {
            const columnOfFour = [i, i + gameSize, i + gameSize * 2, i + gameSize * 3];
            const decidedColor = currentTilesArrangement[i];
            const isBlank = decidedColor === 'blank';

            if ( columnOfFour.every(tile => currentTilesArrangement[tile] === decidedColor) && !isBlank) {
                setScoreDisplay((score) => score + 4);
                columnOfFour.forEach(tile => currentTilesArrangement[tile] = blank);
                return true;
            }
        }
    }

    const dropTiles = () => {
        const tilesToCheck = gameSize * (gameSize - 1);
        const tilesToUpdate = [];

        for ( let i = 0; i < tilesToCheck; i++ ) {
            const firstRow = [...Array(gameSize).keys()];
            const isFirstRow = firstRow.includes(i);

            if (isFirstRow && currentTilesArrangement[i] === blank) {
                let randomColor = Math.floor(Math.random() * candyColors.length);
                tilesToUpdate.push({
                    id: i,
                    value: candyColors[randomColor]
                })
            } else if (currentTilesArrangement[i] !== blank && currentTilesArrangement[i + gameSize] === blank) {
                tilesToUpdate.push(
                    {
                        id: i,
                        value: blank
                    },
                    {
                        id: i + gameSize,
                        value: currentTilesArrangement[i]
                    }
                );
            }
        }

        if (tilesToUpdate.length > 0) {

            setSwappingInProgress(true);

            tilesToUpdate.forEach(item => {
                currentTilesArrangement[item.id] = item.value;
            });

            setCurrentTilesArrangement([...currentTilesArrangement]);

            setTimeout(() => {
                setSwappingInProgress(false);
            }, 50);

            return true;

        }

    }

    const selectTile = (e) => {

        if (!tileBeingDragged) {
            setTileBeingDragged(e.target);    
        } else if (e.target === tileBeingDragged) {
            setTileBeingDragged(null);
            setTileBeingReplaced(null);
        } else {
            if (checkMoveValidity(e)) {
                setTileBeingReplaced(e.target);
            } else {
                setTileBeingDragged(e.target);
                setTileBeingReplaced(null);
            }
        }

    }

    const checkMoveValidity = (e) => {

        const selectedTile = e.target;

        const tileBeingDraggedId = parseInt(tileBeingDragged.dataset.id);
        const tileBeingReplacedId = parseInt(selectedTile.dataset.id);

        const validMoves = [
            tileBeingDraggedId - gameSize,
            tileBeingDraggedId + gameSize
        ];

        if ((tileBeingDraggedId + 1) % gameSize !== 0) {
            validMoves.push(tileBeingDraggedId + 1);
        }

        if (tileBeingDraggedId % gameSize !== 0) {
            validMoves.push(tileBeingDraggedId - 1);
        }

        return validMoves.includes(tileBeingReplacedId);

    }

    const swapTiles = () => {

        if (!!tileBeingDragged && !!tileBeingReplaced) {

            setSwappingInProgress(true);
            moveTilesUI();

            setTimeout(() => {
        
                const tileBeingDraggedId = parseInt(tileBeingDragged.dataset.id);
                const tileBeingReplacedId = parseInt(tileBeingReplaced.dataset.id);

                currentTilesArrangement[tileBeingReplacedId] = tileBeingDragged.dataset.color;
                currentTilesArrangement[tileBeingDraggedId] = tileBeingReplaced.dataset.color;

                const isAColumnOfFour = checkForColumnOfFour();
                const isARowOfFour = checkForRowOfFour();
                const isAColumnOfThree = checkForColumnOfThree();
                const isARowOfThree = checkForRowOfThree();
        
                if (tileBeingReplacedId && (isAColumnOfFour || isAColumnOfThree || isARowOfFour || isARowOfThree)) {
                    setTileBeingDragged(null);
                    setTileBeingReplaced(null);
                } else {
                    setTimeout(() => {
                        tileBeingDragged.classList.remove([...swapClassNames]);
                        tileBeingReplaced.classList.remove([...swapClassNames]);
                    }, 300);        

                    currentTilesArrangement[tileBeingReplacedId] = tileBeingReplaced.dataset.color;
                    currentTilesArrangement[tileBeingDraggedId] = tileBeingDragged.dataset.color;
        
                    setCurrentTilesArrangement([...currentTilesArrangement]);

                    setTileBeingDragged(null);
                    setTileBeingReplaced(null);

                }

                setSwappingInProgress(false);

            }, 300);

        }
    }

    const moveTilesUI = () => {

        const firstTile = tileBeingDragged;
        const secondTile = tileBeingReplaced;

        const firstTileId = parseInt(firstTile.dataset.id);
        const secondTileId = parseInt(secondTile.dataset.id);

        const swapDirection = getSwapDirection(firstTileId, secondTileId);

        firstTile.classList.remove('move-down-anim');
        secondTile.classList.remove('move-down-anim');

        firstTile.classList.add('swap-' + swapDirection + '-first');
        secondTile.classList.add('swap-' + swapDirection + '-second');  

        setTimeout(() => {
            firstTile.classList.remove('swap-' + swapDirection + '-first');
            secondTile.classList.remove('swap-' + swapDirection + '-second');
        }, 600);

    }

    const getSwapDirection = (firstTileId, secondTileId) => {
        const gap = firstTileId - secondTileId;

        switch (gap) {
            case 1:
                return 'left';
            case -1:
                return 'right';
            case gameSize:
                    return 'top';
            case -gameSize:
                    return 'bottom';
            default:
                return '';
        }
    }

    const updateSelection = () => {
        const selectedTile = document.querySelector('.tiles .selected');

        if (!!selectedTile) {
            selectedTile.classList.remove('selected');
        }

        if (!!tileBeingDragged && !tileBeingReplaced) {
            tileBeingDragged.classList.add('selected');
        }
    }

    const ScoreBoard = ({score, style}) => {

        return (
            <div className={'game-controls'} style={style}>
                <div className={'score-board'}>
                    <div>SCORE</div>
                    <div className={'js-score'}>{score}</div>
                </div>
            </div>
        )
    
    }

    useEffect(() => {
        CreateBoard();

        if (window && window.parent) {
            window.parent.postMessage({message: 'loaded'}, '*');
        }

    }, []);


    useEffect(() => {
        updateSelection();
        swapTiles();
    }, [tileBeingDragged, tileBeingReplaced]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (!swappingInProgress) {
                checkForColumnOfFour();
                checkForRowOfFour();
                checkForColumnOfThree();
                checkForRowOfThree();
    
                dropTiles();
                
                setCurrentTilesArrangement([...currentTilesArrangement]);
            }

        }, 50);

        return () => clearInterval(timer);

    }, [checkForColumnOfFour, checkForColumnOfThree, checkForRowOfFour, checkForRowOfThree, dropTiles]);

    return (
        <div className={'game-container match-three-app'}>
            <div className={'tiles'} style={tilesBoardSize}>
                {currentTilesArrangement.map((candyColor, index) => (
                    <div className={'tile move-down-anim ' + candyColor}
                        key={index + candyColor}
                        data-color={candyColor}
                        data-id={index}
                        onClick={selectTile}
                    />
                ))}
            </div>
            <ScoreBoard score={scoreDisplay} style={scoreBoardSize} />
        </div>
    );
}

export default MatchThreeApp;
