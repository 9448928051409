/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './css/common.css';
import './css/match-three.css';
import './css/wheel-of-fortune.css';
import './css/lottery.css';

import { tokenCookies, getDateAfter30Days } from './utils';

import MainMenu from './components/MainMenu';
import MatchThreeApp from './components/MatchThreeApp';
import WheelOfFortune from './components/WheelOfFortune';
import LotteryApp from './components/LotteryApp';


const root = ReactDOM.createRoot(document.getElementById('root'));

const HomePage = () => {
    const [token, setToken] = useState();

    useEffect(() => {

        async function listener(event) {
            if (event.data.token) {
                const extToken = event.data.token;
                setToken(extToken);

                tokenCookies.set('token', extToken, {
                    path: '/',
                    sameSite: 'lax',
                    expires: getDateAfter30Days(),
                });

                localStorage.setItem('userToken', extToken);
            }
        }
        
        window.addEventListener('message', listener);
        return () => document.removeEventListener('message', listener);

    }, []);

    useEffect(() => {
        if (!!token) {
            console.log('token', token);
        }
    }, [token]);

    return (
        <>
            <MainMenu />
            <div className={'game-container'}>
                <h1>МИНИ ИГРЫ</h1>    
            </div>            
        </>
    )
}

const MatchThreePage = () => {
    return (
        <>
            <MainMenu />
            <MatchThreeApp />
        </>
    )
}

const WheelPage = () => {
    return (
        <>
            <MainMenu />
            <WheelOfFortune />
        </>
    )
}

const LotteryPage = () => {
    return (
        <>
            <MainMenu />
            <LotteryApp />
        </>
    )
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: 'match3',
        element: <MatchThreePage />,
    },
    {
        path: 'wheel',
        element: <WheelPage />,
    },
    {
        path: 'lottery',
        element: <LotteryPage />,
    },
    {
        path: 'embed/match3',
        element: <MatchThreeApp />,
    },
    {
        path: 'embed/wheel',
        element: <WheelOfFortune />,
    },
    {
        path: 'embed/lottery',
        element: <LotteryApp />,
    },
  ]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);