import React, { useState } from 'react'
import { Wheel } from 'react-custom-roulette';

// https://github.com/effectussoftware/react-custom-roulette

const WheelOfFortune = () => {
    const data = [
        { option: '5' },
        { option: '5000' },
        {
            option: 'ANDROID',
            image: {
                uri: '/img/android.png',
                offsetX: 0,
                offsetY: 200,
                sizeMultiplier: 0.6
            }
        },
        { option: '10000', style: { textColor: '#177628' } },
        { option: '100' },
        {
            option: 'NASA',
            image: {
                uri: '/img/nasa.png',
                offsetX: 0,
                offsetY: 200,
                sizeMultiplier: 0.6
            }
        },
        { option: '500' },
        { option: '0' },
        {
            option: 'ADIDAS',
            image: {
                uri: '/img/adidas.png',
                offsetX: 0,
                offsetY: 200,
                sizeMultiplier: 0.6
            }
        },
    ];
      
    const backgroundColors = ['#D3D1DD', '#ADD6FC', '#A1DDA9'];
    const textColors = ['rgba(33, 37, 41, 0.8)'];
    const outerBorderColor = '#ffffff';
    const outerBorderWidth = 5;
    const innerBorderColor = '#30261a';
    const innerBorderWidth = 0;
    const innerRadius = 0;
    const radiusLineColor = '#ffffff';
    const radiusLineWidth = 5;
    const fontFamily = 'Nunito';
    const fontWeight = 'bold';
    const fontSize = 20;
    const fontStyle = 'normal';
    const textDistance = 60;
    const spinDuration = 1.0;

    const pointerProps = {
        src: '/img/pointer-green.png'
    }
      
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);

    const handleSpinClick = () => {
        if (!mustSpin) {
            const newPrizeNumber = Math.floor(Math.random() * data.length);
            setPrizeNumber(newPrizeNumber);
            setMustSpin(true);
        }
    };
      
    return (
        <div className={'game-container wheel-of-fortune'}>
            <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data}
                backgroundColors={backgroundColors}
                textColors={textColors}
                fontFamily={fontFamily}
                fontSize={fontSize}
                fontWeight={fontWeight}
                fontStyle={fontStyle}
                outerBorderColor={outerBorderColor}
                outerBorderWidth={outerBorderWidth}
                innerRadius={innerRadius}
                innerBorderColor={innerBorderColor}
                innerBorderWidth={innerBorderWidth}
                radiusLineColor={radiusLineColor}
                radiusLineWidth={radiusLineWidth}
                spinDuration={spinDuration}
                startingOptionIndex={2}
                // perpendicularText
                textDistance={textDistance}
                pointerProps={pointerProps}
                onStopSpinning={() => {
                    setMustSpin(false);
                }}
            />
            <div className={'game-controls'}>
                <button className={'spin-button'} onClick={handleSpinClick}>Крутить</button>
            </div>
        </div>
    )
}

export default WheelOfFortune;