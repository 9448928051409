/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { ReactComponent as ArrowIcon } from '../img/icon/arrow.svg';

import lotteryConfig from '../config/lottery.json';

const { 
    gameSize,
    cardColors,
    blank,
    startScore,
    defaultBet,
    currency,
    labels,
    maxColorOccurenceCount,
} = lotteryConfig;

const paylines = [
    // {
    //     lineOf: 3,
    //     multiplier: 1
    // },
    {
        lineOf: 4,
        multiplier: 3
    },
    {
        lineOf: 5,
        multiplier: 5
    },
];

const LotteryApp = () => {

    const [ currentCardsArrangement, setCurrentCardsArrangement ] = useState([]);
    const [ currentColorsArrangement, setCurrentColorsArrangement ] = useState({});
    const [ scoreReal, setScoreReal ] = useState(startScore);
    const [ scoreDisplay, setScoreDisplay ] = useState(startScore);
    const [ betDisplay, setBetDisplay ] = useState(defaultBet);
    const [ gamePhase, setGamePhase ] = useState('initial');
    const [ controlButtonText, setControlButtonText ] = useState('Start');
    const [ gameCounter, setGameCounter ] = useState(0);
    const [ revealedCards, setRevealedCards ] = useState(0);

    const CreateBoard = () => {
        const blankArrangement = new Array(gameSize * gameSize).fill({
            color: blank,
            isRevealed: false,
        });
        setCurrentCardsArrangement(blankArrangement);
        setGamePhase('initial');
    }

    const getRandomColor = () => {
        return cardColors[Math.floor(Math.random() * cardColors.length)];
    };

    const ShuffleBoard = () => {
        const randomTilesArrangement = [];
        const colorOccurences = {};
        let randomColor = '';

        cardColors.forEach(color => {
            colorOccurences[color] = {
                total: 0,
                revealed: 0
            };
        });

        for ( let i = 0; i < gameSize * gameSize; i++ ) {
            
            do {
                randomColor = getRandomColor();
            } while (colorOccurences[randomColor].total === maxColorOccurenceCount);
               
            colorOccurences[randomColor].total++;

            randomTilesArrangement.push({
                color: randomColor,
                isRevealed: false,
            });
        }

        setCurrentCardsArrangement(randomTilesArrangement);
        setCurrentColorsArrangement(colorOccurences);
    }

    const selectCard = (e) => {
        e.stopPropagation();
        const cardClicked = e.currentTarget;
        const cardsArrangement = [...currentCardsArrangement];
        const colorOccurences = {...currentColorsArrangement};
        const card = cardsArrangement[cardClicked.dataset.id];

        if (gamePhase === 'play' && !card.isRevealed) {
            setTimeout(() => {
                card.isRevealed = true;
                colorOccurences[cardClicked.dataset.color].revealed++;
                setRevealedCards(count => count + 1);
                setCurrentCardsArrangement(cardsArrangement);
                setCurrentColorsArrangement(colorOccurences);
            }, 100)
        }

        if (gamePhase === 'initial') {
            const controlButton = document.querySelector('.js-control-button');
            controlButton.classList.add('flashing');

            setTimeout(() => {
                controlButton.classList.remove('flashing');
            }, 1000);
        }

    }

    const controlButtonClick = (e) => {

        if (gamePhase === 'play') {
            const cardsArrangement = [...currentCardsArrangement];
            cardsArrangement.forEach(card => {
                card.isRevealed = true;
            });
            setRevealedCards(gameSize * gameSize);
            setCurrentCardsArrangement(cardsArrangement);
            setGamePhase('revealed');
        } else if (scoreReal > 0) {
            ShuffleBoard();
            setGamePhase('play');
        }

    }

    const LegendBoard = () => {
        return (
            <div className={'game-info'}>
                <div className={'legend-board'}>
                    <h3>{labels.prizes}</h3>
                    <ul>
                        {paylines.map((item, index) => (
                            <li key={index}>{item.lineOf} &times; <span className={'tile'}></span> {labels.bet} &times; {item.multiplier}</li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }

    const ScoreBoard = ({score}) => {
        return (
            <div className={'score-board'}>
                <h3>{labels.account}</h3>
                <div className={'js-score'}>{score} {currency}</div>
            </div>
        )
    }

    const BetBoard = ({score}) => {
        return (
            <div className={'bet-board'}>
                <h3>{labels.bet}</h3>
                <div className={'value js-bet'}>
                    <span>{score} {currency}</span>
                    <div className={'change-bet'}>
                        <span className={'increase js-increase-bet'} onClick={changeBet}><ArrowIcon/></span>
                        <span className={'reduce js-reduce-bet'} onClick={changeBet}><ArrowIcon/></span>
                    </div>     
                </div>
            </div>
        )
    }

    const changeBet = (e) => {
        e.stopPropagation();

        if (gamePhase !== 'play') {
            let betStep = 0;
            const changeMode = e.currentTarget.classList.contains('js-reduce-bet') ? 'reduce' : 'increase' ;
    
            if (changeMode === 'increase') {
                betStep = betDisplay > 9 ? 5 : 1;
            } else if (betDisplay > 1) {
                betStep = betDisplay > 14 ? -5 : -1;
            }
    
            setBetDisplay(prop => Math.min(prop + betStep, scoreReal));
        }
    }

    const getReward = () => {

        const multipliers = new Array(maxColorOccurenceCount).fill(0);
        let reward = 0;

        paylines.forEach(item => {
            multipliers[item.lineOf - 1] = item.multiplier;
        });

        cardColors.forEach(color => {
            const colorOccurences = gamePhase === 'revealed' 
                ? currentColorsArrangement[color].total
                : currentColorsArrangement[color].revealed;

            if (colorOccurences > 0) {
                reward = reward + betDisplay * multipliers[colorOccurences - 1];
            }
        });

        return reward;
    }


    useEffect(() => {
        CreateBoard();
        setBetDisplay(defaultBet);
    }, []);


    useEffect(() => {
        if (currentCardsArrangement.filter(card => card.isRevealed).length === gameSize * gameSize) {
            setGamePhase('revealed');
        }
    }, [ currentCardsArrangement ]);


    useEffect(() => {
        if (gamePhase === 'play' && revealedCards > 0) {
            setScoreDisplay(scoreReal + getReward());
        }
    }, [ revealedCards ]);


    useEffect(() => {
        let newScore = scoreReal;

        if ( gamePhase === 'revealed' ) {
            newScore = newScore + getReward();
            setScoreReal(newScore);
            setScoreDisplay(newScore);
            setBetDisplay(bet => Math.min(bet, newScore));
        } else if ( gamePhase === 'play') {
            setGameCounter(gameCounter => gameCounter + 1);
            setScoreReal(score => score - betDisplay);
            setScoreDisplay(score => score - betDisplay);
        }

        setControlButtonText(
            gamePhase === 'play' 
                ? labels.openAll 
                : newScore > 0 
                    ? labels.start
                    : labels.gameOver
        );

        if (newScore === 0 && gamePhase !== 'gameover') {
            setGamePhase('gameover');
        }

    }, [ gamePhase ]);


    return (
        <div className={ 'game-container lottery mode-' + gamePhase }>

            <LegendBoard />

            <div className={'lottery-board'}>
                {currentCardsArrangement.map((card, index) => (
                    <div className={ 'lottery-card ' + card.color + (card.isRevealed ? ' rotate' : '') }
                        key={gameCounter * 100 + index}
                        data-color={card.color}
                        data-id={index}
                        onClick={selectCard}
                    >
                        <div className={'lottery-card-cover'}></div>
                        <div className={'lottery-card-content'}></div>
                    </div>
                ))}
            </div>

            <div className={'game-controls'}>
                <ScoreBoard score={scoreDisplay} />
                <BetBoard score={betDisplay} />
                <div className={'control-button'}>
                    <button 
                        className={'js-control-button'} 
                        onClick={controlButtonClick}
                    >
                        {controlButtonText}
                    </button>
                </div>
            </div>
        </div>
    )

}


export default LotteryApp;